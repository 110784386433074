<template>
  <div>
    <NuxtLayout :name="layout">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts">
import { useWebsiteStore } from '~/units/website/store';
import { usePolarisStore } from '~/units/polaris/store';
import { useCoreStore } from '~/units/core/store';
import type { Company, LsCompany } from '~/types';
import { usePropertiesStore } from '~/units/properties/stores';
import { useAppMode } from '~/composables';
import { ROUTES } from './constants/ui.constants';

export default defineNuxtComponent({
  name: 'App',

  data: () => ({
    layout: '',
  }),

  head(context) {
    if (useAppMode().server && context.ssrContext) {
      const config = context.ssrContext.runtimeConfig;

      const websiteStore = useWebsiteStore();

      const company: Company | null = websiteStore.company;
      const lsCompany: LsCompany = <LsCompany>context.ssrContext?.event.context.ls_company;

      const AKYA_URL = company?.ls_environment_connection_id === 2 ? config.LS_AKYA_PRODUCTION_URL : config.LS_AKYA_URL;

      const isBlogsPage = context.$router.currentRoute.value.path.includes(ROUTES.BLOGS);
      const blogTracking = lsCompany.tracking_sites.find((tracking: any) => tracking.base_url.includes(ROUTES.BLOGS));
      const blogTrackingUniqueID = blogTracking?.unique_id;

      const script = [];
      if (config.IS_AKYA_ACTIVE) {
        script.push({
          key: 'akyaTracking',
          src: `${AKYA_URL}ice.js`,
          id: isBlogsPage ? blogTrackingUniqueID : lsCompany.website_tracking.unique_id,
        });
      }

      if (config.APP_STAGE === 'production') {
        script.push({
          src: `/common/js/common.js`,
        });
      }

      script.push({
        src: `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_CLIENT_KEY}`,
      });

      /* script.push({
        key: 'akyaForm',
        type: 'text/javascript',
        src: `${AKYA_URL}js/form/app.min.js`,
        body: true,
        async: false,
      }); */

      const faviconTypes = [
        {
          rel: 'shortcut icon',
          sizes: '16x16 24x24 32x32 48x48 64x64',
          href: 'favicon.ico',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: 'favicon-57.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '57x57',
          href: 'favicon-57.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: 'favicon-72.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: 'favicon-114.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: 'favicon-120.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: 'favicon-144.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: 'favicon-152.png',
        },
      ];

      const faviconLinks = faviconTypes.map((type) => {
        return {
          ...type,
          href: websiteStore.company?.favicon,
        };
      });

      return {
        title: company?.name,
        script,
        link: [
          ...faviconLinks,
          {
            href: '/common/css/neuron.css',
            rel: 'stylesheet',
            key: 'css/neuron.css',
            type: 'text/css',
          },
          {
            rel: 'icon',
            type: 'image/x-icon',
            href: websiteStore.company?.favicon,
          },
        ],
        meta: [
          {
            name: 'theme-color',
            media: '(prefers-color-scheme: light)',
            content: company?.light_browser_theme_color || '',
          },
          {
            name: 'theme-color',
            media: '(prefers-color-scheme: dark)',
            content: company?.dark_browser_theme_color || '',
          },
        ],
      };
    }
    return {};
  },

  computed: {},

  watch: {
    $route(a, b) {
      setTimeout(() => this.runAkyaScript(), 0);

      /* if (a.name !== b.name) {
        window.scrollTo(0, 0);
      } */
    },
  },

  setup() {
    const route = useRoute();
    const query = { ...route.query };

    const propertiesStore = usePropertiesStore();
    const coreStore = useCoreStore();

    Object.keys(query).forEach((key) => {
      if (['contact_id', 'session', 'operation', 'state'].includes(key)) {
        delete query[key];
      } else if (key.includes('[]')) {
        propertiesStore.setSpecialCriteria(
          key.replace('[]', ''),
          Array.isArray(query[key]) ? query[key] : [query[key]],
        );
        delete query[key];
      }
    });
    const extraObj = {};
    if (coreStore.requestConfig.desired_to_go_page_url) {
      extraObj.path = coreStore.requestConfig.desired_to_go_page_url;
    }

    if (Object.keys(route.query).length !== Object.keys(query).length) {
      navigateTo({
        query,
        ...extraObj,
      });
    } else if (Object.keys(extraObj).length && extraObj.path && route.path !== extraObj.path) {
      navigateTo({
        ...extraObj,
      });
    }
  },

  async created() {
    // set layout
    const websiteStore = useWebsiteStore();
    this.layout = websiteStore.layout;

    if (useAppMode().client) {
      const polarisStore = usePolarisStore();
      await polarisStore.init();
    }
  },

  mounted() {
    const coreStore = useCoreStore();
    coreStore.updateDeviceSize();

    this.$nextTick(() => {
      window.addEventListener('resize', coreStore.updateDeviceSize);
    });
  },

  unmounted() {
    const coreStore = useCoreStore();

    this.$nextTick(() => {
      window.removeEventListener('resize', coreStore.updateDeviceSize);
    });
  },

  methods: {
    runAkyaScript() {
      if (useAppMode().client) {
        // removes unnecessary akya iframe
        const oldAkya = document.getElementsByName('akyaIframe');
        if (oldAkya && oldAkya[0]) {
          oldAkya[0].remove();
        }

        const event = new Event('DOMContentLoaded');
        document.dispatchEvent(event);
      }
    },
  },
});
</script>
