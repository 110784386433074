<template>
  <div>something went wrong</div>
</template>

<script lang="ts">
import { useMonitoringStore } from '~/units/monitoring/store';

export default defineNuxtComponent({
  async setup() {
    if (useAppMode().server) {
      const url = useRequestURL();
      const headers = useRequestHeaders();
      const config = useRuntimeConfig();

      console.log(url, 'ERROR');

      const isWebsiteNotFoundError = url.searchParams.get('message') === 'WEBSITE_NOT_FOUND';
      const isNotFoundPage = url.searchParams.get('url').includes('/not-found');
      const isRequestFailedError = url.searchParams.get('message')?.includes('Request failed');

      if (!isWebsiteNotFoundError && !isNotFoundPage && !isRequestFailedError && url.pathname === '/__nuxt_error') {
        const monitoringStore = useMonitoringStore();
        const urlPath = url.searchParams.get('url');

        await monitoringStore.sendLog({
          app_stage: config.APP_STAGE,
          meta: {
            status_code: 404,
            path: urlPath,
            origin: url.origin,
            host: url.host,
            headers: {
              'user-agent': headers['user-agent'],
            },
          },
        });

        try {
          if (!isNotFoundPage) {
            navigateTo({
              path: '/not-found',
              query: {
                path: urlPath,
              },
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
});
</script>
