import { useContact, usePages } from '~/composables/hydration';
import { useContactsStore } from '~/units/contacts/store';
import { useWebsiteStore } from '~/units/website/store';
import { useAuthStore } from '~/units/auth/store';
import { useCoreStore } from '~/units/core/store';

export function isRequestNecessary(url: string | undefined) {
  const routeMatches = ['template', 'css'];

  return !routeMatches.some((match) => url?.includes(match));
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server && isRequestNecessary(to.path)) {
    const app = useNuxtApp();

    if (
      app.ssrContext &&
      app.ssrContext.event &&
      app.ssrContext.event.context &&
      app.ssrContext.event.context.sitemap &&
      app.ssrContext.event.context.sitemap.pages
    ) {
      const websiteStore = useWebsiteStore(app.$pinia);
      const coreStore = useCoreStore(app.$pinia);
      coreStore.initRequest(app.ssrContext.event.context.config);
      websiteStore.setTheme(app.ssrContext?.event.context.layout);
      websiteStore.setSitemap(app.ssrContext?.event.context.sitemap);

      usePages();
      useContact();
      websiteStore.setCompany(app.ssrContext.event.context.company);

      if (app?.ssrContext?.event?.context?.sessions) {
        const contactsStore = useContactsStore(app.$pinia);
        contactsStore.setSessionToStore(app?.ssrContext?.event?.context?.sessions['neuron-touch']);
        if (app.ssrContext.event.context.contact) {
          contactsStore.setContactInfo(app.ssrContext.event.context.contact);

          const authStore = useAuthStore();
          authStore.authenticate({
            contact_id: app.ssrContext.event.context.contact.contact_id,
          });
        }
      }
    }
  } else if (process.client && isRequestNecessary(to.path)) {
  }
});
