import { defineStore } from 'pinia';
import { MonitoringService } from '~/units/monitoring/services';
import { useWebsiteStore } from '~/units/website/store';
import { useContactsStore } from '~/units/contacts/store';

export const useMonitoringStore = defineStore({
  id: 'monitoring',
  state: () => {
    return {};
  },
  actions: {
    async sendLog(body = {}) {
      const websiteStore = useWebsiteStore();
      const contactsStore = useContactsStore();

      const contactId = (() => {
        if (contactsStore.contactInfo) {
          return contactsStore.contactInfo.contact_id;
        }
        return null;
      })();

      const monitoringService = new MonitoringService();
      await monitoringService.sendLog({
        is_error: true,
        company_id: websiteStore.company?._id,
        contact_id: contactId,
        ...body,
      });
    },
  },
});
