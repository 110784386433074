export default class TransactionTypesConstants {
  static SALES = 1;

  static LETTINGS = 2;

  static BOTH = 3;

  static SALES_ACTION = 'sale';
  static LETTINGS_ACTION = 'rent';

  static SALES_KEYWORD = 'Sales';
  static LETTINGS_KEYWORD = 'Lettings';

  static computeType(id: number) {
    if (id === this.SALES) {
      return 'Sales';
    }
    if (id === this.LETTINGS) {
      return 'Lettings';
    }
    return 'Sales & Lettings';
  }
}
