import type { RouteLocationRaw } from 'vue-router';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';
import { useCoreStore } from '~/units/core/store';
import { useContactsStore } from '~/units/contacts/store';

export default defineNuxtComponent({
  name: 'GlobalMixin',

  computed: {
    ...mapState(useWebsiteStore, ['themeDesign', 'company', 'currentPage', 'colors']),
    ...mapState(useCoreStore, ['isMobileDevice']),

    contactSession() {
      const contactsStore = useContactsStore();
      return contactsStore.contactInfo;
    },
  },

  methods: {
    errorMessage(e: string | undefined) {
      this.$toast.open({
        message: e || 'Error',
        type: 'error',
      });
    },

    successMessage(e: string | undefined) {
      this.$toast.open({
        message: e || 'Success',
        type: 'success',
      });
    },

    warningMessage(e: string | undefined) {
      this.$toast.open({
        message: e || 'Warning',
        type: 'warning',
      });
    },

    redirectTo(route: RouteLocationRaw, isHardRedirection = false) {
      navigateTo(route, {
        external: true,
      });

      /*

      if (isHardRedirection) {
      } else {
        const router = useRouter();

        router
          .push(route)
          .then(() => {
            note('router has changed');
            if (isHardRedirection) {
              router.go(0);
            }
          })
          .catch(() => note(`route couldn't be changed`, true));
      } */
    },
  },
});
