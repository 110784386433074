export default class MediaTypesConstants {
  static PHOTOS = 1;

  static FLOOR_PLAN = 2;

  static BROCHURES = 3;

  static VIRTUAL_TOURS = 4;

  static AUDIO_TOURS = 5;

  static EPC_S = 6;

  static EPC_GRAPHS = 7;

  static VIDEO = 10;

  static OTHER = 12;

  static MEDIA_DOCUMENTS = 11;

  static MEDIA_LINKS = 14;

  static GALLERY_TYPES = [MediaTypesConstants.PHOTOS, MediaTypesConstants.VIRTUAL_TOURS, MediaTypesConstants.VIDEO];

  static MEDIA_CUSTOM_LINKS = [
    MediaTypesConstants.BROCHURES,
    MediaTypesConstants.MEDIA_DOCUMENTS,
    MediaTypesConstants.MEDIA_LINKS,
    MediaTypesConstants.OTHER,
  ];
}
