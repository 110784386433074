import { FetchError } from 'ofetch';
import { NuxtApp } from '#app';
import { _AsyncData } from '#app/composables/asyncData';
import type { H3Event } from 'h3';
import { NetworkCaller } from '~/hydration/network';
import { useCoreStore } from '~/units/core/store';

class NeuronError {
  message: string;

  constructor(options: { message: string }) {
    this.message = options.message;
  }
}

export enum RequestProxy {
  N_AXON = 'nAxon', // new indirect connection via neuron proxy
  S_AXON = 'sAxon', // old indirect connection via es manager proxy
  INTERNAL = 'internal', // internal api
  LS_AXON = 'lsAxon',
  POLARIS = 'polaris',
  NEURON_API = 'neuronApi',
  AKYA_AXON = 'akyaAxon',
}

export enum RequestMethod {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface RequestBody {}

export enum RequestName {
  // neuron api requests
  FETCH_PLACES = 'fetchPlaces',
  GET_GEOCODE_BY_PLACE_ID = 'getGeocodeByPlaceId',
  GET_GEOCODE_BY_FULL_ADDRESS = 'getGeocodeByFullAddress',
  GET_ADDRESSES_BY_POSTCODE = 'getAddressesByPostcode',
  GET_GEOCODE_BY_LAT_AND_LNG = 'getGeocodeByLatLong',
  SEND_MAGIC_LINK_REQUEST = 'sendMagicLinkRequest',

  ADD_POTENTIAL_VIEWING = 'addPotentialViewing',

  // internal
  REFRESH_TOKEN = 'refreshToken',

  // others
  FETCH_PROPERTIES = 'fetchProperties',
  FETCH_BLOGS = 'fetchBlogs',
  FETCH_BLOG = 'fetchBlog',
  FETCH_PROPERTY = 'fetchProperty',
  FETCH_LOCATION = 'fetchLocation',
  FETCH_ADDRESS_DETAIL = 'fetchAddressDetail',

  FETCH_PROPERTY_TYPES = 'fetchPropertyTypes',

  FETCH_POSTCODE = 'fetchPostcode',

  FETCH_BY_GEOCODE = 'fetchByGeocode',

  FETCH_ADDRESS_BY_LAT_LONG = 'fetchAddressByLatLong',

  FETCH_APPLICANT_QUESTIONS = 'fetchApplicantQuestions',

  FETCH_SUBSCRIPTIONS = 'fetchSubscriptions',

  FETCH_SETTINGS = 'fetchSettings',

  CREATE_APPLICANT = 'createApplicant',

  TOUCH_POST = 'touchPost',

  FETCH_CONTACT = 'fetchContact',

  FETCH_APPLICANTS = 'fetchApplicants',

  FETCH_APPLICANT = 'fetchApplicant',

  UPDATE_APPLICANT = 'updateApplicant',

  SEND_LEAD = 'sendLead',

  SEND_PING = 'sendPing',

  SEND_ACTIVITY = 'sendActivity',

  SEND_LOG = 'sendLog',

  SEND_FORM = 'sendForm',
}

type Query = {
  [key: string]: boolean | number | string;
};

interface ListResponse {
  data: never[];
  meta: { count?: number; total?: number };
}

interface ApiResponse {
  result: ListResponse;
}

export const logResultContext = (
  event: H3Event,
  requestName: RequestName,
  keyword: string,
  response,
  contactId?: number | null | undefined,
) => {
  const config = useRuntimeConfig();

  const entityName = (() => {
    if (requestName === 'fetchProperties') {
      return 'properties';
    } else if (requestName === 'fetchBlogs') {
      return 'blogs';
    }
    return null;
  })();

  // dont wait
  $fetch.raw('search_logs', {
    baseURL: config.API_URL,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${event.context.access_token}`,
    },
    body: {
      keyword,
      entity_name: entityName,
      found_data_count: response && response.meta ? response.meta.total : 0,
      contact_id: contactId,
    },
    params: {
      company_id: event.context.company._id,
    },
  });
};

const logResult = (
  nuxtApp: NuxtApp,
  requestName: RequestName,
  keyword: string,
  response,
  contactId?: number | null | undefined,
) => {
  nuxtApp.runWithContext(() => {
    const event = nuxtApp.ssrContext?.event;
    if (event) {
      logResultContext(event, requestName, keyword, response, contactId);
    }
  });
};

const beforeRequestInterceptor = async (nuxtApp: NuxtApp, query: Query, requestName: RequestName) => {
  const neuronCompanyId: string = <string>nuxtApp.ssrContext?.event.context.company._id;

  const cacheResult = await accessFil(nuxtApp, 'READ', query, requestName, neuronCompanyId);
  if (cacheResult) {
    if (query.searchByKeyword && requestName === 'fetchBlogs') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      logResult(nuxtApp, requestName, query.searchByKeyword, cacheResult);
    }

    return cacheResult;
  }
};

const afterResponseInterceptor = async (
  nuxtApp: NuxtApp,
  response: _AsyncData<ApiResponse, FetchError<never> | null>,
  requestName: RequestName,
  query: Query,
) => {
  const neuronCompanyId: string = <string>nuxtApp.ssrContext?.event.context.company._id;

  if (response.error.value) {
    throw response.error.value;
  }

  const successResponse = response.data.value.result;

  if (query.searchByKeyword && requestName === 'fetchBlogs') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    logResult(nuxtApp, requestName, query.searchByKeyword, successResponse);
  }

  await accessFil(nuxtApp, 'SAVE', query, requestName, neuronCompanyId, successResponse);

  return successResponse;
};

const pipeBody = (method: RequestMethod, body: any) => {
  return ['HEAD', 'GET'].includes(method) ? undefined : body;
};

const accessFil = async (
  nuxtApp: NuxtApp,
  action: 'READ' | 'SAVE',
  query: Query = {},
  requestName: RequestName,
  neuronCompanyId: string,
  body = {},
) => {
  if (action === 'READ') {
    try {
      const result = await nuxtApp.runWithContext(() => {
        return useFetch('/api/fil', {
          method: 'GET',
          query: {
            ...query,
            request_name: requestName,
            neuron_company_id: neuronCompanyId,
          },
        });
      });
      if (result.data.value && result.data.value.data) {
        return result.data.value.data;
      }
    } catch (e) {}
  } else if (action === 'SAVE') {
    try {
      return await nuxtApp.runWithContext(() => {
        return useFetch('/api/fil', {
          method: 'POST',
          query: {
            ...query,
            request_name: requestName,
            neuron_company_id: neuronCompanyId,
          },
          body,
        });
      });
    } catch (e) {}
  }
};

const internalCaller = async (
  path: string,
  requestName: RequestName,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const requestBody = pipeBody(method, body);

  if (process.server) {
  } else if (process.client) {
    const { data, error } = await useFetch(`/api/internal/${path}`, {
      method,
      query,
      body: requestBody,
    });
    if (error.value) {
      throw error.value;
    }
    return data.value.result;
  }
};

const polarisCaller = async (
  path: string,
  requestName: RequestName,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const requestBody = pipeBody(method, body);

  if (process.server) {
  } else if (process.client) {
    const coreStore = useCoreStore();

    return await new NetworkCaller().call(`/api/polaris/${path}`, {
      method,
      query: {
        ...query,
        request_name: requestName,
      },
      body: requestBody,
      headers: {
        'x-app-access-token': coreStore.requestConfig?.app_token?.access_token,
      },
    });
  }
};

const neuronApiCaller = async (
  path: string,
  requestName: RequestName,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const requestBody = pipeBody(method, body);

  if (process.server) {
    const nuxtApp = useNuxtApp();
    const config = useRuntimeConfig();

    const response = await nuxtApp.runWithContext(() => {
      return useFetch(`${config.API_URL}${path}`, {
        method,
        headers: {
          Authorization: `Bearer ${nuxtApp.ssrContext?.event.context.access_token}`,
        },
        params: query,
        body: requestBody,
      });
    });
    if (response && response.data && response.data.value && response.data.value.result) {
      return response.data.value.result;
    }

    throw new NeuronError({
      message: 'Not found!',
    });
  } else if (process.client) {
    const coreStore = useCoreStore();

    const result = await new NetworkCaller().call(`/api/neuron/${path}`, {
      method,
      query: {
        ...query,
        request_name: requestName,
      },
      body: requestBody,
      headers: {
        'x-app-access-token': coreStore.requestConfig?.app_token?.access_token,
      },
    });

    return { result };
  }
};

const lsAxonCaller = async (
  path: string,
  requestName: RequestName,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const requestBody = pipeBody(method, body);

  if (process.server) {
    const nuxtApp = useNuxtApp();
    const config = useRuntimeConfig();

    const response: _AsyncData<ApiResponse, FetchError<never> | null> = <
      _AsyncData<ApiResponse, FetchError<never> | null>
    >await nuxtApp.runWithContext(() => {
      return useFetch(`${config.LS_API_URL}${path}`, {
        method,
        headers: {
          Authorization: `Bearer ${nuxtApp.ssrContext?.event.context.ls_company_access_token}`,
        },
        params: query,
        body: requestBody,
      });
    });
    if (response && response.data && response.data.value && response.data.value.result) {
      return response.data.value.result;
    }

    throw new NeuronError({
      message: 'Not found!',
    });
  } else if (process.client) {
    const coreStore = useCoreStore();

    return await new NetworkCaller().call(`/api/ls-axon/${path}`, {
      method,
      query: {
        ...query,
        request_name: requestName,
      },
      body: requestBody,
      headers: {
        'x-app-access-token': coreStore.requestConfig?.app_token?.access_token,
      },
    });
  }
};

const sAxonCaller = async (
  path: string,
  requestName: RequestName,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const requestBody = pipeBody(method, body);

  if (process.server) {
    const nuxtApp = useNuxtApp();
    const config = useRuntimeConfig();

    await beforeRequestInterceptor(nuxtApp, query, requestName);

    const response: _AsyncData<ApiResponse, FetchError<never> | null> = <
      _AsyncData<ApiResponse, FetchError<never> | null>
    >await nuxtApp.runWithContext(() => {
      return useFetch(`${config.LS_API_URL}search_engine/${path}`, {
        method,
        headers: {
          Authorization: `Bearer ${nuxtApp.ssrContext?.event.context.ls_company_access_token}`,
        },
        params: query,
        body: requestBody,
      });
    });
    return await afterResponseInterceptor(nuxtApp, response, requestName, query);
  } else if (process.client) {
    const coreStore = useCoreStore();

    return await new NetworkCaller().call(`/api/s-axon/${path}`, {
      method,
      query: {
        ...query,
        request_name: requestName,
      },
      body: requestBody,
      headers: {
        'x-app-access-token': coreStore.requestConfig?.app_token?.access_token,
      },
    });
  }
};

const akyaAxonCaller = async (
  path: string,
  requestName: RequestName,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const requestBody = pipeBody(method, body);

  if (process.server) {
    const nuxtApp = useNuxtApp();
    const config = useRuntimeConfig();

    await beforeRequestInterceptor(nuxtApp, query, requestName);

    const response: _AsyncData<ApiResponse, FetchError<never> | null> = <
      _AsyncData<ApiResponse, FetchError<never> | null>
    >await nuxtApp.runWithContext(() => {
      return useFetch(`${config.LS_AKYA_PRODUCTION_URL}api/v2/${path}`, {
        method,
        headers: {
          'content-type': 'application/json',
          'User-Agent': 'Neuron',
        },
        params: query,
        body: requestBody,
      });
    });
    return await afterResponseInterceptor(nuxtApp, response, requestName, query);
  } else if (process.client) {
    const { data, error } = await useFetch(`/api/akya-axon/${path}`, {
      method,
      query: {
        ...query,
        request_name: requestName,
      },
      body: requestBody,
    });
    if (error.value) {
      throw error.value;
    }
    return data.value.result;
  }
};

const nAxonCaller = async (
  path: string,
  requestName: RequestName,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const requestBody = pipeBody(method, body);

  if (process.server) {
    const nuxtApp = useNuxtApp();
    const config = useRuntimeConfig();

    const lsCompanyId = nuxtApp.ssrContext?.event.context.company.ls_company_id;
    const token = nuxtApp.ssrContext?.event.context.ls_client_access_token;

    await beforeRequestInterceptor(nuxtApp, query, requestName);

    const response: _AsyncData<ApiResponse, FetchError<never> | null> = <
      _AsyncData<ApiResponse, FetchError<never> | null>
    >await nuxtApp.runWithContext(() => {
      return useFetch(`${config.LS_API_URL}public/neuron_proxy/neuron/${path}`, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...query,
          company_id: lsCompanyId,
          app_env: 'production',
        },
        body: requestBody,
      });
    });
    return await afterResponseInterceptor(nuxtApp, response, requestName, query);
  } else if (process.client) {
    const { data, error } = await useFetch(`/api/n-axon/${path}`, {
      method,
      query: {
        ...query,
        request_name: requestName,
      },
      body: requestBody,
    });
    if (error.value) {
      throw error.value;
    }
    return data.value.result;
  }
};

export const useCaller = (
  proxy: RequestProxy,
  requestName: RequestName,
  path: string,
  method: RequestMethod,
  query: Query = {},
  body: RequestBody = {},
) => {
  const clearedPath = path.startsWith('/') ? path.slice(1) : path;

  if (proxy === RequestProxy.S_AXON) {
    return sAxonCaller(clearedPath, requestName, method, query, body);
  } else if (proxy === RequestProxy.N_AXON) {
    return nAxonCaller(clearedPath, requestName, method, query, body);
  } else if (proxy === RequestProxy.INTERNAL) {
    return internalCaller(clearedPath, requestName, method, query, body);
  } else if (proxy === RequestProxy.LS_AXON) {
    return lsAxonCaller(clearedPath, requestName, method, query, body);
  } else if (proxy === RequestProxy.POLARIS) {
    return polarisCaller(clearedPath, requestName, method, query, body);
  } else if (proxy === RequestProxy.NEURON_API) {
    return neuronApiCaller(clearedPath, requestName, method, query, body);
  } else if (proxy === RequestProxy.AKYA_AXON) {
    return akyaAxonCaller(clearedPath, requestName, method, query, body);
  }
};
