import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime);

export const uiDateFormat = (date: any, time = false, second = false) => {
  let format = 'DD/MM/YYYY';
  if (time) {
    format += ' HH:mm';
  }
  if (second) {
    format += ':ss';
  }
  return dayjs(date).format(format);
};

export const backendDateFormat = (date: any, time = false, second = false) => {
  let format = 'YYYY-MM-DD';
  if (time) {
    format += ' HH:mm';
  }
  if (second) {
    format += ':ss';
  }
  return dayjs(date).format(format);
};

export const fromNow = (date: Date) => {
  const format = dayjs(date).fromNow();
  if (format === 'a day ago') {
    return '1 day ago';
  }
  if (format === 'a month ago') {
    return '1 month';
  }
  if (format === 'a year ago') {
    return '1 year';
  }
  return format;
};

export const formatDate = (date: Date, format = 'D MMMM YYYY') => {
  return dayjs(date).format(format);
};

export const addDayToDate = (date: Date, days: number) => {
  const dayjsDate = dayjs(date);

  return dayjsDate.add(days, 'day');
};
