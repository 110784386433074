export default class CommunicationMethodsConstants {
  static MOBILE_PHONE_NUMBER = 1;

  static EMAIL = 2;

  static LANDLINE_NUMBER = 7;

  static PHONE_NUMBERS = [
    CommunicationMethodsConstants.MOBILE_PHONE_NUMBER,
    CommunicationMethodsConstants.LANDLINE_NUMBER,
  ];

  static OPTIONS = [
    {
      communication_method_id: CommunicationMethodsConstants.EMAIL,
      name: 'Email',
    },
    {
      communication_method_id: CommunicationMethodsConstants.MOBILE_PHONE_NUMBER,
      name: 'Mobile Phone',
    },
    {
      communication_method_id: CommunicationMethodsConstants.LANDLINE_NUMBER,
      name: 'Landline Phone',
    },
  ];
}
export { CommunicationMethodsConstants };
