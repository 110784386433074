import type { Client } from '~/types';

export const generateString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getSubdomain = (host: string): string => {
  const partsOfUrl = host.split('.');
  if (partsOfUrl.length > 1) {
    if (partsOfUrl[0] === 'www') {
      return partsOfUrl[1];
    }
    return partsOfUrl[0];
  }
  return '';
};

export const identifyClient = (host: string): Client => {
  const testServers = ['localhost:4044', 'localdomain:4044', 'beta.neuronwebsites.co.uk'];

  const isTestServer: boolean = testServers.some((testServer) => host.includes(testServer));
  if (isTestServer) {
    return {
      identifier_type: 'subdomain',
      value: getSubdomain(host),
    };
  }

  return {
    identifier_type: 'domain',
    value: host,
  };
};

export const isRequestNecessary = (url: string | undefined): boolean => {
  const routeMatches = [
    '__nuxt_error',
    'template',
    'favicon.ico',
    '.ico',
    'png',
    'jpg',
    'jpeg',
    '__nuxt_island/',
    '_nuxt',
    '/api/fil',
    '/api/internal-memory',
    '/api/_auth',
    '/sitemap.xml',
    '/_vercel/image',
    '/_ipx',
    '.css',
    '/_vercel/insights',
  ];

  return !routeMatches.some((match) => url?.includes(match));
};

export const note = (text: string, isError = false) => {
  // eslint-disable-next-line no-console
  console.log(text);
};

export const produceKey = () => Math.random().toString(36).substr(2, 9);

export function capitalizeFirstLetters(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const showPerformance = (t1, t0) => {
  return `${((t1 - t0) / 1000).toFixed(2)} sec`;
};
