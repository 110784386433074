import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import init_45global from "/vercel/path0/middleware/init.global.ts";
import validate_45query_45global from "/vercel/path0/middleware/validateQuery.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  init_45global,
  validate_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}